<template>
  <div class="home">
    <nav-header />
    <router-view />
  </div>
</template>

<script>
import NavHeader from './../components/NavHeader.vue'

export default {
  name: 'Home',
  components: {
    NavHeader
  }
}
</script>
<style lang="scss">
</style>
